<template>
  <div class="personal-info">
    <el-form
      ref="ruleFormRef"
      label-position="right"
      label-width="270px"
      :model="ruleForm"
      :inline="true"
      :rules="rules"
    >
      <div class="maintitle">Personal Information</div>
      <el-form-item label="Chinese name:"  :rules="[{required: true, message: 'This field cannot be left blank.'}]" prop="cn_name">
        <el-input v-model="ruleForm.cn_name"  placeholder="例如，张三"/>
      </el-form-item>
      <el-form-item label="English name:"  :rules="[{required: true, message: 'This field cannot be left blank.'}]" prop="en_name">
        <el-input v-model="ruleForm.en_name" placeholder="For example, John Smith"/>
      </el-form-item>
      <el-form-item label="Gender:"  :rules="[{required: true, message: 'This field cannot be left blank.'}]" prop="gender">
        <el-radio-group
          v-model="ruleForm.gender"
          fill="#5EA6F8"
          text-color="#5EA6F8"
        >
          <el-radio v-for="item in genderInfo" :label="item.en_name">{{item.en_name}}</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="Date of birth:"  :rules="[{required: true, message: 'This field cannot be left blank.'}]" prop="birthday">
        <el-date-picker v-model="ruleForm.birthday" type="date" placeholder="select date" format="YYYY/MM/DD"
        value-format="YYYY-MM-DD" :disabledDate="datePickerOptions"/>
      </el-form-item>
      <el-form-item label="Tel:"  :rules="[{required: true, message: 'This field cannot be left blank.'}]" prop="phone">
        <el-input v-model="ruleForm.phone" />
      </el-form-item>
      <el-form-item label="WeChat ID:" prop="wechat">
        <el-input v-model="ruleForm.wechat" placeholder="it means we are allowed to reach you via WeChat."/>
      </el-form-item>
      <el-form-item label="Personal homepage or Lab homepage:" prop="home_page" class="homepage">
        <el-input v-model="ruleForm.home_page" 
          show-word-limit
          maxlength="100"
          type="textarea"
          resize="none"
        />
      </el-form-item>
      <el-form-item label="Social media account:" prop="social_media" class="social">
        <el-input v-model="ruleForm.social_media"
          show-word-limit
          maxlength="100"
          type="textarea"
          resize="none"
          placeholder="Weibo/Instagram/Twitter "
        />
      </el-form-item>
      <el-form-item label="Email:" prop="email">
        <el-input v-model="adminEmail" disabled/>
      </el-form-item>
      <el-form-item label="Highest level of education:"  :rules="[{required: true, message: 'This field cannot be left blank.'}]" prop="highest_education">
        <el-select v-model="ruleForm.highest_education" class="m-2" placeholder="Please select">
          <el-option v-for="item in educationInfo" :label="item.en_name" :value="item.id" :key="item.id"/>
        </el-select>
      </el-form-item>
      <el-form-item label="University/Institution/Company:"  :rules="[{required: true, message: 'This field cannot be left blank.'}]" prop="company">
        <el-input v-model="ruleForm.company" />
      </el-form-item>
      <el-form-item label="Position:"  :rules="[{required: true, message: 'This field cannot be left blank.'}]" prop="position">
        <el-input v-model="ruleForm.position" />
      </el-form-item>
      <el-form-item label="Research field:"  :rules="[{required: true, message: 'This field cannot be left blank.'}]" prop="subject">
        <el-select v-model="ruleForm.subject" class="m-2" placeholder="Please select">
          <el-option v-for="item in subjectInfo" :label="item.en_name" :value="item.id" :key="item.id"/>
        </el-select>
      </el-form-item>
      <el-form-item label="Research interest:"  :rules="[{required: true, message: 'This field cannot be left blank.'}]" prop="research_direction" class="interest">
        <el-input v-model="ruleForm.research_direction"
          show-word-limit
          type="textarea"
          resize="none"
          maxlength="50" 
          placeholder="e.g. Stem cell, photoelectric sensor"
        />
      </el-form-item>
      <el-form-item
        label="Achievements in innovation & Reasons to apply:"
        :rules="[{required: true, message: 'This field cannot be left blank.'}]"
        prop="research_results"
        class="text"
      >
        <el-input
          v-model="ruleForm.research_results"
          show-word-limit
          maxlength="600"
          type="textarea"
          resize="none"
        />
      </el-form-item>
      <div class="maintitle">Education Background <span class="edutitle">Tip: Only highest level of education is required. </span></div>
      <div class="add-edu">
        <div class="add-item" v-for="(domain, index) in ruleForm.education_list" :key="domain.id">
          <el-form-item label="University/Institution:" :prop="'education_list.' + index + '.school'"  :rules="[{required: true, message: 'This field cannot be left blank.'}]">
            <el-input v-model="domain.school" />
          </el-form-item>
          <el-form-item label="Academic degree:" :prop="'education_list.' + index + '.education'"  :rules="[{required: true, message: 'This field cannot be left blank.'}]">
            <!-- <el-input v-model="domain.education" /> -->
            <el-select v-model="ruleForm.education_list[index].education" value-key="education" class="m-2" placeholder="Please select">
              <el-option v-for="item in educationInfo" :label="item.en_name" :value="item.id" :key="item.id"/>
            </el-select>
          </el-form-item>
          <div class="major-duration">
            <el-form-item label="Major:" :prop="'education_list.' + index + '.major'"  :rules="[{required: true, message: 'This field cannot be left blank.'}]">
              <el-input v-model="domain.major" />
            </el-form-item>
            <div class="durationbox">
              <el-form-item label="Duration:" required class="duration" :prop="'education_list.' + index + '.start_time'"  :rules="[{required: true, message: 'This field cannot be left blank.'}]">
                <!-- <el-input v-model="domain.start_time" /> -->
                <el-date-picker v-model="domain.start_time" type="year" value-format="YYYY" format="YYYY" :disabledDate="(time) => {return datePickerStartOptions(time, domain.end_time)}"></el-date-picker>
              </el-form-item>
              <span>-</span>
              <el-form-item required class="duration" :prop="'education_list.' + index + '.end_time'"  :rules="[{required: true, message: 'This field cannot be left blank.'}]">
                <!-- <el-input v-model="domain.end_time" /> --> 
                <el-date-picker v-model="domain.end_time" type="year" value-format="YYYY" format="YYYY" :disabledDate="(time) =>  { return datePickerEndOptions(time, domain.start_time)}"></el-date-picker>
              </el-form-item>
            </div>
          </div>
          <!-- <el-form-item label="Duration:" required prop="Duration" class="duration" :prop="'education_list.' + index + '.Duration'" :rules="[{required: true, message: 'Duration is required'}]">
            <el-input v-model="domain.start_time" />
            <span>-</span>
            <el-input v-model="domain.end_time" />
          </el-form-item> -->
          <div class="add-btn"><span @click="addDomain">+ add</span><span @click.prevent="removeDomain(domain)">Delete</span></div>
        </div>
      </div>
      <div class="maintitle">Attachment</div>
      <div class="upload-box-con">
          <el-form-item
          label="Curriculum Vitae:"
           :rules="[{required: true, message: 'This field cannot be left blank.'}]"
          prop="cn_resume"
          class="upload-box"
        >
        <!-- :file-list="[{name: `${ruleForm.cn_name}_CV.pdf`, url: `${ruleForm.cn_resume}`}]" -->
        <el-upload
            class="upload-item"
            :show-file-list="false"
            action="http-request"
            accept=".PDF"
            :on-success="handleAvatarSuccess"
            :before-upload=" (file) =>  { return beforeAvatarUpload(file, 'resume')}"
            :http-request=" (file) =>  { return uploadFile(file, 'cn_resume')}"
            
          >
            <img src="@/assets/images/upload.png" alt="" />
            <div><div>Curriculum Vitae（Chinese）</div><span>Tip: PDF Only.</span></div>
          </el-upload>
          <div class="tips" slot="tip" v-if="ruleForm.cn_resume">
              <p class="href-title">{{ruleForm.cn_name}}_个人简历.pdf</p>
              <p class="operate">
                <span><a :href="ruleForm.cn_resume" target="_blank">preview</a></span>
                <span @click="ruleForm.cn_resume=''">delete</span>
              </p>
          </div>
        </el-form-item>
          <el-form-item
          label=""
           :rules="[{required: true, message: 'This field cannot be left blank.'}]"
          prop="en_resume"
          class="upload-box"
        >
        <el-upload
          class="upload-item"
            :show-file-list="false"
            action="http-request"
            accept=".PDF"
            :on-success="handleAvatarSuccess"
            :before-upload=" (file) =>  { return beforeAvatarUpload(file, 'resume')}"
            :http-request=" (file) =>  { return uploadFile(file, 'en_resume')}"
        >
          <img src="@/assets/images/upload.png" alt="" />
          <div><div>Curriculum Vitae（English）</div><span>Tip: PDF Only.</span></div>
        </el-upload>
         <div class="tips" slot="tip" v-if="ruleForm.en_resume">
           <p class="href-title"> {{ruleForm.en_name}}_CV.pdf</p>
              <p class="operate">
                <span><a :href="ruleForm.en_resume" target="_blank">preview</a></span>
                <span @click="ruleForm.en_resume=''">delete</span>
              </p>
          </div>
        </el-form-item>
      </div>
      <div class="upload-box-con">
        <el-form-item
          label="Personal Statement:"
           :rules="[{required: true, message: 'This field cannot be left blank.'}]"
          prop="cn_personal_statement"
          class="upload-box"
        >
          <el-upload
            class="upload-item"
            :show-file-list="false"
            action="http-request"
            accept=".PDF"
            :on-success="handleAvatarSuccess"
            :before-upload=" (file) =>  { return beforeAvatarUpload(file, 'personal_statement')}"
            :http-request=" (file) =>  { return uploadFile(file, 'cn_personal_statement')}"
          >
            <img src="@/assets/images/upload.png" alt="" />
            <div><div>Personal Statement（Chinese）</div><span>Tip: PDF Only.</span></div>
          </el-upload>
          <div class="tips" slot="tip" v-if="ruleForm.cn_personal_statement">
              <p class="href-title"> {{ruleForm.cn_name}}_个人陈述.pdf</p>
              <p class="operate">
                <span><a :href="ruleForm.cn_personal_statement" target="_blank">preview</a></span>
                <span @click="ruleForm.cn_personal_statement=''">delete</span>
              </p>
          </div>
        </el-form-item>
        <el-form-item
          label=""
           :rules="[{required: true, message: 'This field cannot be left blank.'}]"
          prop="en_personal_statement"
          class="upload-box"
        >
          <el-upload
            class="upload-item"
            :show-file-list="false"
            action="http-request"
            accept=".PDF"
            :on-success="handleAvatarSuccess"
            :before-upload=" (file) =>  { return beforeAvatarUpload(file, 'personal_statement')}"
            :http-request=" (file) =>  { return uploadFile(file, 'en_personal_statement')}"
          >
            <img src="@/assets/images/upload.png" alt="" />
            <div><div>Personal Statement（English）</div><span>Tip: PDF Only.</span></div>
          </el-upload>
          <div class="tips" slot="tip" v-if="ruleForm.en_personal_statement">
              <p class="href-title"> {{ruleForm.en_name}}_Personal Statement.pdf</p>
              <p class="operate">
                <span><a :href="ruleForm.en_personal_statement" target="_blank">preview</a></span>
                <span @click="ruleForm.en_personal_statement=''">delete</span>
              </p>
          </div>
        </el-form-item>
      </div>
      <el-form-item
        label="ID card/Passport："
         :rules="[{required: true, message: 'This field cannot be left blank.'}]"
        prop="passport_copy"
        class="upload-box upload-id"
      >
        <el-upload
          class="upload-item"
           :show-file-list="false"
            action="http-request"
            accept=".PDF,image/png,image/jpg,image/jpeg"
            :on-success="handleAvatarSuccess"
            :before-upload=" (file) =>  { return beforeAvatarUpload(file, 'passport')}"
            :http-request=" (file) =>  { return uploadFile(file, 'passport_copy')}"
        >
          <img src="@/assets/images/upload.png" alt="" />
          <div>Tip: Please upload the scanned copy of ID card (front and back) / Passport (information page) in<span> PDF, JPG or PNG format</span>.The scanned document is only used for TR35 eligibility verification.</div>
        </el-upload>
        <div class="tips" slot="tip" v-if="ruleForm.passport_copy">
            <p class="href-title"> {{ruleForm.cn_name}}_ID.{{passportType}}</p>
              <p class="operate">
                <span><a :href="ruleForm.passport_copy" target="_blank">preview</a></span>
                <span @click="ruleForm.passport_copy=''">delete</span>
              </p>
        </div>
      </el-form-item>
      <el-form-item label="" class="btns">
        <el-button plain @click="handleClick(0)">Previous</el-button>
        <el-button type="primary"  @click="submitForm(ruleFormRef)">Save</el-button>
        <el-button plain @click="handleClick(2)" :disabled="isDisable">Next</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
import { defineComponent, reactive, ref, onMounted, getCurrentInstance, toRefs, watch } from "vue";
import { useI18n } from 'vue-i18n'
import { Ajax, MessageTips } from "@/utils";
import * as qiniu from "qiniu-js";
export default defineComponent({
  components: {},
  props: {
    fillStatus: String,
    applyId: Number,
    isShowBeforeInfo: Boolean
  },
  setup(props, {emit}) {
    const { t } = useI18n()
    const ruleFormRef = ref();
    let ruleFormData = reactive({
      ruleForm: {
        year: getCurrentInstance() ? getCurrentInstance().appContext.config.globalProperties.$year : 2024,
        apply_id: '',
        cn_name: "",
        en_name: "",
        gender: "",
        birthday: "",
        phone: "",
        wechat: "",
        // email: "",
        highest_education: "",
        company: "",
        position: "",
        subject: "",
        research_direction: "",
        research_results: "",
        cn_resume: "",
        en_resume: "",
        cn_personal_statement: "",
        en_personal_statement: "",
        passport_copy: "",
        education_list: [
          {
            school: '',
            education: '',
            major: '',
            start_time: '',
            end_time: '',
            id: null
          }
        ]
    }
    });
    const educationList = ref([])
    const adminEmail = ref(localStorage.getItem('adminEmail') || '')
    const checkCnName = (rule, value, callback) => {
      // console.log('reg.test(value)', value)
      if (!value) {
        return callback(new Error('Chinese name is required'))
      }
    };

    const rules = ref({
      // cn_name: [
      //   {
      //     required: true,
      //     message: 'Chinese name is required',
      //     trigger: "blur",
      //     validator: checkCnName,
      //   },
      // ]
    });
    const upload = ref();
    const baseUrl = ref(window.env.baseUrl);
    const fillStatus = ref(props.fillStatus);
    const applyId = ref(props.applyId)
    const educationInfo = ref()
    const subjectInfo = ref()
    const genderInfo = ref()
    const qiniuConfig = reactive({
      file_name: '', 
      token: '',
      config: {},
      link_host: ''
    })
    const isDisable = ref(true)
    const isDelete = ref(false)
    const passportType = ref('')
    const isLoading = ref(true)
    const datePickerOptions = (time) => {
      let year=new Date().getFullYear()-35+0.1
      return time.getTime() > Date.now()||time.getTime() < new Date(JSON.stringify(year))
    }

    const datePickerStartOptions = (time, endTime) => {
      // console.log('endTime',time.getTime(), endTime, Date.now())
      if (endTime){
        return time.getFullYear() > endTime
      } else {
        return time.getTime() > Date.now()
      }
    }

    const datePickerEndOptions = (time, startTime) => {
      return (time.getFullYear() < startTime) || (time.getTime() > Date.now())
    }
    const handleExceed = (files) => {
      upload.value.clearFiles();
      upload.value.handleStart(files[0]);
    };
    // const submitUpload = () => {
    //   upload.value.submit()
    // }
    const handleChange = (file, fileList) => {
      // console.log("file, fileList", file, fileList);
    };

    const handleAvatarSuccess = (e) => {
      // console.log('上传成功', e)/
    };
    const beforeAvatarUpload = async(file, business_code) => {
      // console.log('file, data', file, business_code)
      const res = await Ajax.POST({
        url: '/api/uploadConfig',
        data: {
          business_code,
          file_name: file.name,
        }
      })
      if (res.data.code === 10000) {
        Object.assign(qiniuConfig, res.data.data)
      }
      return true;
    }

    const uploadFile = (e, params) => {
      const file = e.file;
      const putExtra = {
        fname: file.name, //文件原始文件名
      };
      const { file_name, token, config, link_host } = qiniuConfig;
      const observable = qiniu.upload(file, file_name, token, putExtra, config);
      observable.subscribe({
        next: (result) => {
           console.log('next', result)
        },
        error: (err) => {
           console.log('error', err)
        },
        complete: (result) => {
          // console.log('complete', result)
          const file_url = link_host + result.key;
          MessageTips.success("Uploaded successfully!");
          ruleFormData.ruleForm[params] = file_url
          if(file_url){
            passportType.value = file_url.slice(-3)
          }
           ruleFormRef.value.clearValidate(`${params}`)
          // console.log('ruleFormData.ruleForm', ruleFormData.ruleForm)
          // this.$emit("updateFile", {
          //   file: file_url,
          //   name: file.name
          // });
        },
      });
    }

    const addDomain = () => {
      ruleFormData.ruleForm.education_list.push({
        school: '',
        education: '',
        major: '',
        start_time: '',
        end_time: '',
      })
      // educationList.value = ruleFormData.ruleForm.education_list
    }

    const removeDomain = (item) => {
      const index = ruleFormData.ruleForm.education_list.indexOf(item)
      // educationList.value = JSON.parse(JSON.stringify(ruleFormData.ruleForm.education_list))
      if (index !== -1 && ruleFormData.ruleForm.education_list.length > 1) {
        ruleFormData.ruleForm.education_list.splice(index, 1)
        // educationList.value.forEach(ite => {
        //   if(ite.id === item.id){
        //     ite.is_del = true
        //   }
        // })
        let Arr =  educationList.value
        if(item.id){
          Arr.push({...item, is_del: true})
        }
        educationList.value = Arr
        isDelete.value = true
      }
    }

    const handleClick = step => {
      // console.log('step====', step, isDisable.value)
      if (step === 2){
        if(!isDisable.value){
          emit('setStep', step)
        }
      } else {
        isDisable.value = false
        emit('setStep', step)
      }
      // isDisable.value = false
      //   emit('setStep', step)
    }

    //获取个人信息详情
    const getPersonInfo = async() => {
      const res = await Ajax.POST({
        url: '/api/getPersonalInformation',
        data: {
          apply_id: applyId.value
        }
      })
      if (res.data.code === 10000){
        // console.log('ruleFormData', ruleFormData.ruleForm)
        ruleFormData.ruleForm = Object.assign({}, ruleFormData.ruleForm, res.data.data)
        ruleFormData.ruleForm.gender = res.data.data.gender === 1 ? 'male' : 'female'
        ruleFormData.ruleForm.education_list = res.data.data.education_list.length ? res.data.data.education_list : [
          {
            school: '',
            education: '',
            major: '',
            start_time: '',
            end_time: '',
            id: null
          }
        ]
        if(res.data.data.education_list.length){
          ruleFormData.ruleForm.education_list.forEach(item => {
            item.start_time = item.start_time.toString()
            item.end_time = item.end_time.toString()
          })
        }
        // educationList.value = ruleFormData.ruleForm.education_list
        console.log('个人信息详情res',res, ruleFormData.ruleForm)
        if(res.data.data.cn_name){
           if(fillStatus.value === 3 && props.isShowBeforeInfo){
             isDisable.value = true
           } else {
             isDisable.value = false
           }
          
        }
        if(fillStatus.value === 3 && props.isShowBeforeInfo){
          applyId.value = null
          ruleFormData.ruleForm.apply_id = ''
        }
      
        if(res.data.data.passport_copy){
          passportType.value = res.data.data.passport_copy.slice(-3)
        }
        // console.log('ruleFormData.ruleForm', ruleFormData.ruleForm)
      }
      isLoading.value = false
      
    }
    
    // 获取性别数据
    const getGender = async() => {
      const res = await Ajax.POST({
        url: '/api/getSelect',
        data: {
          type: 'gender'
        }
      })
      if (res.data.code === 10000){
        genderInfo.value = res.data.data
      }
      // console.log('获取性别数据res',res)
    }

    // 获取学历数据
    const getEducationInfo = async() => {
      const res = await Ajax.POST({
        url: '/api/getSelect',
        data: {
          type: 'education'
        }
      })
      if (res.data.code === 10000){
        educationInfo.value = res.data.data
      }
      // console.log('获取学历数据res',res)
    }

    // 获取研究领域数据
    const getSubjectInfo = async() => {
      const res = await Ajax.POST({
        url: '/api/getSelect',
        data: {
          type: 'subject'
        }
      })
      if (res.data.code === 10000){
        subjectInfo.value = res.data.data
      }
      // console.log('获取研究领域数据res',res)
    }

     // 个人信息保存
    const savePersonInfo = async() => {
      const listArr = [...educationList.value, ...ruleFormData.ruleForm.education_list]
      // console.log('listArr', listArr)
      let data = Object.assign({}, ruleFormData.ruleForm, {gender: ruleFormData.ruleForm.gender === 'male' ? 1 : 2}, {
        education_list: listArr}, {year: getCurrentInstance() ? getCurrentInstance().appContext.config.globalProperties.$year : 2024}
        )
      delete data.email
      delete data.gender_name
      delete data.highest_education_name
      delete data.subject_name
      delete data.user_id
      data.education_list.forEach(item => {
        if(!item.id){
          item.id = null
        }
        delete item.education_name
      })
      // console.log('保存data', data)
      const res = await Ajax.POST({
        url: '/api/savePersonalInformation',
        data
      })
      if (res.data.code === 10000){
        // console.log('个人信息保存res',res)
        MessageTips.success('Saved Successfully!')
        applyId.value = res.data.data
        // getPersonInfo()
        emit('setApplyId', res.data.data)
        emit('getFillStatus')
        isDisable.value = false
        // localStorage.setItem('successfulStep', 1)
      }
      
    }

    const submitForm = (formEl) => {
      console.log("ruleFormRef", ruleFormRef.value, formEl);
      if (!formEl) return;
      formEl.validate((valid) => {
        if (valid) {
          console.log("submit!");
          savePersonInfo()
        } else {
          console.log("error submit!");
          return false;
        }
      });
    };

    watch(ruleFormData, (newValue, oldValue) => { //直接监听
      // console.log("ruleForm改变了", newValue, oldValue);
      if(!isDelete.value){
        // educationList.value = ruleFormData.ruleForm.education_list
      }
      isDelete.value = false
      localStorage.setItem('userInfo', JSON.stringify({
        cn_name: ruleFormData.ruleForm.cn_name,
        en_name: ruleFormData.ruleForm.en_name
      }))
      // console.log('educationList.value', educationList.value)
    })

    onMounted(() => {
      // console.log('fillStatus.value', fillStatus.value)
      // if (fillStatus.value === 2 || (fillStatus.value === 3 && props.isShowBeforeInfo)){
      //   getPersonInfo()
      // }
      if(applyId.value){
        if(fillStatus.value === 3){
          props.isShowBeforeInfo && getPersonInfo()
        } else {
          getPersonInfo()
        }
      } else {
        isLoading.value = false
      }
      
      getEducationInfo()
      getSubjectInfo()
      getGender()
    })
    return {
      t,
      ...toRefs(ruleFormData),
      ruleFormRef,
      rules,
      submitForm,
      handleExceed,
      handleChange,
      handleAvatarSuccess,
      beforeAvatarUpload,
      baseUrl,
      addDomain,
      removeDomain,
      handleClick,
      fillStatus,
      applyId,
      educationInfo,
      getEducationInfo,
      subjectInfo,
      getSubjectInfo,
      genderInfo,
      getGender,
      savePersonInfo,
      educationList,
      uploadFile,
      qiniuConfig,
      isDisable,
      adminEmail,
      datePickerOptions,
      isDelete,
      passportType,
      isLoading,
      datePickerStartOptions,
      datePickerEndOptions
    };
  },
});
</script>
<style lang="scss" scoped>
.personal-info {
  padding: 0 40px 0 20px;
  .maintitle {
    border-left: 8px solid #e34c3e;
    padding-left: 10px;
    font-size: 21px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #333333;
    margin: 50px 0 20px 0;
    .edutitle{
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #e34c3e;
      margin-left: 10px;
    }
  }
  // :deep(.el-form-item){
  //   margin-bottom: 30px;
  // }
  :deep(.el-form-item__label) {
    font-size: 15px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #262626;
  }
  :deep(.el-radio__input.is-checked .el-radio__inner) {
    border-color: #5ea6f8;
    background-color: #5ea6f8;
  }
  :deep(.el-radio__inner:hover) {
    border-color: #5ea6f8;
  }
  :deep(.el-radio__input.is-checked + .el-radio__label) {
    color: #333333;
  }
  :deep(.el-form-item__content) {
    width: 350px;
    .el-input__inner {
      width: 350px;
      height: 34px;
      border-radius: 4px;
    }
  }
  .homepage, .social, .interest{
    :deep(.el-form-item__content) {
      width: 980px;
      .el-input__inner {
        width: 980px;
        height: 34px;
        border-radius: 4px;
      }
  }
  }
  :deep(.el-date-editor){
    width: 350px;
  }
  
  
 
  .text {
    width: fit-content;
    :deep(.el-form-item__content) {
      width: 100%;
    }
    :deep(.el-textarea),
    :deep(.el-textarea__inner) {
      width: 980px;
      height: 173px;
      border-radius: 4px;
      font-size: 16px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #666666;
    }
  }
  :deep(.el-textarea),
  :deep(.el-textarea__inner) {
    width: 980px;
    // height: 173px;
    border-radius: 4px;
    font-size: 16px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #666666;
  }
  .add-edu {
    .add-item {
      position: relative;
      .add-btn {
        position: absolute;
        right: -10px;
        top: 10px;
        > span {
          margin-left: 12px;
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #666666;
          cursor: pointer;
        }
        > span:nth-of-type(1) {
          color: #448df7;
        }
      }
      .major-duration{
        display: flex;
        .durationbox{
          display: flex;
          align-items: center;
          >span{
            margin: -20px 10px 0 0;
          }
          .duration{
            margin-right: 6px;
            :deep(.el-form-item__content) {
              width: 164px;
              display: flex;
              align-items: center;
              justify-content: space-between;
              .el-input__inner {
                width: 164px;
              }
            }
          }
          .duration:nth-last-of-type(1){
            margin-right: 0;
          }
        }
      }
    }
  }
  .upload-box-con{
    display: flex;
  }
  .upload-box {
    margin-bottom: 40px;
    :deep(.el-form-item__content) {
      width: 100%;
      display: flex;
      flex-direction: column;
      .upload-item {
        width: 256px;
        // height: 125px;
        background: #fafafa;
        border-radius: 8px;
        border: 1px solid #e6e6e6;
        .el-upload {
          width: 100%;
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          padding: 34px 0 19px;
          > div {
            font-size: 14px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #262626;
            line-height: 20px;
            padding: 0 17px;
            margin-top: 19px;
            > span {
              color: #e34c3e;
            }
          }
        }
      }
      .tips{
       width: 256;
       display: flex;
        .href-title{
          width: 150px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
        }
        .operate {
          color: #1890ff;
          span {
            a{ color: #1890ff;text-decoration: none;}
            margin-left: 6px;
            cursor: pointer;
          }
        }
      }
      .upload-item:nth-of-type(odd) {
        margin-right: 120px;
      }
    }
  }
  .upload-id {
    width: 100%;
  }
  .btns {
    width: 100%;
    margin-top: 50px;
    :deep(.el-form-item__content) {
      display: flex;
      align-items: center;
      justify-content: center;
      .el-button + .el-button {
        margin-left: 60px;
      }
      .el-button {
        width: 150px;
        height: 50px;
        border-radius: 4px;
        font-size: 18px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
      }
    }
  }
}
</style>
