<template>
  <el-dialog v-model="centerDialogVisible" width="500px" center :show-close="false" custom-class="is-submit">
    <div class="title" v-if="isSubmit">Are you sure you want to submit it ?</div>
    <div class="title" v-else>You have registered or applied before. Do you want to update the previous application materials?</div>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="handleCancel" plain class="cancel">No</el-button>
        <el-button @click="handleOk" class="submit">Yes</el-button>
      </span>
    </template>
  </el-dialog>
</template>
<script>
  import { defineComponent, ref } from 'vue'
  export default defineComponent({
    components: {},
    props: {
      year: String,
      isShowDialog: Boolean,
      isSubmit: Boolean
    },
    setup(props, {emit}) {
      const centerDialogVisible = ref(props.isShowDialog)
      const isSubmit = ref(props.isSubmit)
      const handleCancel = () => {
        if(isSubmit.value){
          emit('closeDialog', false)
        } else {
          emit('setStep', 1)
          emit('closeDialog', false)
        }
       
      }

      const handleOk = () => {
        if(isSubmit.value){
          emit('submitSure')
        } else {
          emit('setStep', 1)
          emit('closeDialog', true)
        }
      }
      return {
        centerDialogVisible,
        handleCancel,
        handleOk,
        isSubmit
      }
    }
  })
</script>
<style lang="scss">
  .is-submit {
    border-radius: 8px;
    padding: 40px;
    .el-dialog__header {
      padding: 0;
    }
    .el-dialog__body {
      padding: 0 !important;
    }
    .title {
      font-size: 16px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #000000;
    }
    .el-dialog__footer {
      padding: 50px 0 0;
    }
    .dialog-footer {
      .cancel {
        width: 140px;
        height: 44px;
        background: #9b9b9b;
        border-radius: 4px;
        border: none;
        font-size: 16px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #ffffff;
      }
      .submit {
        width: 140px;
        height: 44px;
        background: #e34c3e;
        border-radius: 4px;
        border: none;
        font-size: 16px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #ffffff;
        padding: 0;
        margin-left: 118px;
      }
    }
  }
</style>
