<template>
  <div class="other-info">
    <el-form label-position="right" :model="ruleForm" :inline="true" ref="ruleFormRef">
      <!-- <div class="maintitle">About Innovation</div>
      <div v-for="(item, index) in ruleForm.question_list" :key="item.question_id">
        <div class="quest">{{index + 1}}.{{item.en_title}}</div>
        <el-form-item
          label="Answer(Chinese):"
          required
          :prop="'question_list.' + index + '.cn_answer'" :rules="[{required: true, message: 'Answer(Chinese) is required'}]"
          class="text"
        >
          <el-input
            v-model="item.cn_answer"
            show-word-limit
            maxlength="2000"
            type="textarea"
            resize="none"
          />
        </el-form-item>
        <el-form-item
          label="Answer(English):"
          required
          :prop="'question_list.' + index + '.en_answer'" :rules="[{required: true, message: 'Answer(English) is required'}]"
          class="text"
        >
          <el-input
            v-model="item.en_answer"
            show-word-limit
            maxlength="2000"
            type="textarea"
            resize="none"
          />
        </el-form-item>
      </div> -->
      
      <!-- <div class="maintitle">Supplementary Information</div> -->
      <el-form-item
        label=""
        prop="about_innovation"
        class="upload-box"
        :rules="[{required: true, message: 'This field cannot be left blank.'}]"
      >
        <div slot="label" class="default_label">
          <div class="el-form-item__label"><span class="require-icon">*</span> Questions about innovation:</div>
          <div class="tips_msg">Please visit <a href="https://docs.qq.com/doc/DTmFOQUZBeUVNa2JC" target="_blank">创新问题</a> for the questions.</div>
        </div>
        <div class="upload-con">
          <el-upload
            class="upload-item"
            action="http-request"
            accept=".PDF"
            :on-success="handleAvatarSuccess"
            :before-upload=" (file) =>  { return beforeAvatarUpload(file, 'about_innovation')}"
            :http-request=" (file) =>  { return uploadFile(file, 'about_innovation')}"
            :on-remove="handleRemove"
            :show-file-list="false"
          >
            <img src="@/assets/images/upload.png" alt="" />
            <div>Tips: PDF Only. For the question list, please refer to the application guide.</div>
          </el-upload>
          <div class="little-tips little-tips1" slot="tip" v-if="ruleForm.about_innovation">
             <p class="href-title"> {{en_name}}_Questions about innovation.pdf</p>
              <p class="operate">
                <span><a :href="ruleForm.about_innovation" target="_blank">preview</a></span>
                <span @click="ruleForm.about_innovation=''">delete</span>
              </p>
            </div>
        </div>
      </el-form-item>
      <el-form-item
        label=""
        prop="additional_judge_information"
        class="upload-box"
      >
      <div slot="label" class="default_label">
          <div class="el-form-item__label">Other supplementary info:</div>
          <div class="tips_msg">Eg: reference letters, press release, etc.</div>
        </div>
        <div class="upload-con">
          <el-upload
            class="upload-item"
            action="http-request"
            accept=".PDF"
            :on-success="handleAvatarSuccess"
            :before-upload=" (file) =>  { return beforeAvatarUpload(file, 'additional_judge_information')}"
            :http-request=" (file) =>  { return uploadFile(file, 'additional_judge_information')}"
            :on-change="handleChange"
            :on-remove="handleRemove"
            :show-file-list="false"
            :disabled="isUpDisable"
          >
            <img src="@/assets/images/upload.png" alt="" />
            <div>Tip: PDF Only (no more than 5)</div>
          </el-upload>
          <div class="little-tips" slot="tip" v-if="ruleForm.additional_judge_information">
              <div v-for="item in ruleForm.additional_judge_information" :key="item.url">
                <div class="name">{{item.realName}}</div>
                <div class="del" style="margin-right:10px">
                  <a :href="item.url" target="_blank">preview</a>
                </div>
                <div class="del" @click="handleDelete(item)">delete</div>
              </div>
          </div>
        </div>
      </el-form-item>
      <!-- <el-form-item
        label="Supplementary information for the review process:"
        prop="additional_information"
        class="text supplement"
      >
        <el-input
          v-model="ruleForm.additional_information"
          show-word-limit
          maxlength="200"
          type="textarea"
          resize="none"
          placeholder="Are there judges you need to avoid conflicts of interest? If so, please explain the reasons briefly."
        />
      </el-form-item> -->
      <el-form-item label="" class="btns">
        <el-button plain @click="handleClick(2)">Previous</el-button>
        <el-button type="primary" @click="submitForm(ruleFormRef)">Save</el-button>
        <el-button plain @click="handleClick(4)" :disabled="isDisable">Next</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
import { defineComponent, reactive, ref, onMounted, watch } from "vue";
import { useI18n } from "vue-i18n";
import { Ajax, MessageTips } from "@/utils";
import * as qiniu from "qiniu-js";
export default defineComponent({
  components: {},
   props: {
    fillStatus: String,
    applyId: Number
  },
  setup(props, {emit}) {
    const { t } = useI18n();
    const fillStatus = ref(props.fillStatus);
    const applyId = ref(props.applyId)
    const isDisable = ref(true)
    const ruleForm = ref({
      question_list: [],
      additional_information: '',
      additional_judge_information: []
    });
    const qiniuConfig = reactive({
      file_name: '', 
      token: '',
      config: {},
      link_host: ''
    })
    const ruleFormRef = ref();
    const upload = ref();
    const baseUrl = ref("");
    const en_name = ref(JSON.parse(localStorage.getItem('userInfo')) && JSON.parse(localStorage.getItem('userInfo')).en_name || '')
    const isUpDisable = ref(false)
    const handleExceed = (files) => {
      upload.value.clearFiles();
      upload.value.handleStart(files[0]);
    };
    // const submitUpload = () => {
    //   upload.value.submit()
    // }
    const handleChange = (file, fileList) => {
      ruleFormRef.value.validateField(['about_innovation'])
    };
    
    const handleAvatarSuccess = () => {};
    const beforeAvatarUpload = async(file)=> {
      const res = await Ajax.POST({
        url: '/api/uploadConfig',
        data: {
          business_code: 'additional',
          file_name: file.name,
        }
      })
      if (res.data.code === 10000) {
        // console.log('qiniuConfig', qiniuConfig)
        Object.assign(qiniuConfig, res.data.data)
      }
      return true;
    }
    const uploadFile = (e, type) => {
      const file = e.file;
      const putExtra = {
        fname: file.name, //文件原始文件名
      };
      const { file_name, token, config, link_host } = qiniuConfig;
      const observable = qiniu.upload(file, file_name, token, putExtra, config);
      observable.subscribe({
        next: (result) => {
          //  console.log('next', result)
        },
        error: (err) => {
          //  console.log('error', err)
        },
        complete: (result) => {
          // console.log('complete', result)
          const file_url = link_host + result.key;
          if (type === 'about_innovation'){
            ruleForm.value.about_innovation = file_url
            MessageTips.success("Uploaded successfully!");
          } else {
            let Arr = ruleForm.value.additional_judge_information ? ruleForm.value.additional_judge_information : []
            const en_name = JSON.parse(localStorage.getItem('userInfo')).en_name
            if (Arr.length < 5){
              Arr.push({
                realName: `${en_name}_Additional information_${e.file.name}`,
                name: e.file.name,
                url: file_url
              })
              MessageTips.success("Uploaded successfully!");
            }
            ruleForm.value.additional_judge_information = Arr
          }
          // console.log('ruleForm.value.additional_judge_information', ruleForm.value.additional_judge_information)
          // this.$emit("updateFile", {
          //   file: file_url,
          //   name: file.name
          // });
        },
      });
    }
    const handleDelete = (item) => {
      const index = ruleForm.value.additional_judge_information.indexOf(item)
      ruleForm.value.additional_judge_information.splice(index, 1)
    }
    const handleRemove = (file, fileList) => {
      // console.log('file, fileList', file, fileList)
    }
    const handleClick = step => {
      emit('setStep', step)
    }

    //获取报名信息详情-其他信息
    const getOtherInformation = async() => {
      const res = await Ajax.POST({
        url: '/api/getOtherInformation',
        data: {
          apply_id: applyId.value
        }
      })
      if (res.data.code === 10000){
        ruleForm.value.question_list = res.data.data.question_list
        ruleForm.value.about_innovation = res.data.data.about_innovation
        ruleForm.value.additional_judge_information = res.data.data.additional_judge_information
        ruleForm.value.additional_information = res.data.data.additional_information
        ruleForm.value.additional_judge_information && ruleForm.value.additional_judge_information.forEach(item => {
          // console.log('item', item, )
          const en_name = JSON.parse(localStorage.getItem('userInfo')).en_name
          item.realName = `${en_name}_Additional information_${item.name}`
        })
        let status = res.data.data.question_list && res.data.data.question_list.every(item => {return item.cn_answer})
        // console.log('status', status, ruleForm.value.additional_judge_information)
        if(res.data.data.about_innovation){
          isDisable.value = false
        }
      }
    }

    // 保存信息
    const saveOtherInformation = async() => {
      let Arr = []
      ruleForm.value.question_list && ruleForm.value.question_list.length && ruleForm.value.question_list.forEach(item => {
        Arr.push({
          id: null,
          question_id: item.question_id,
          cn_answer: item.cn_answer,
          en_answer: item.en_answer
        })
      })
      const res = await Ajax.POST({
        url: '/api/saveOtherInformation',
        data: {
          apply_id: applyId.value,
          // question_list: Arr,
          // additional_information: ruleForm.value.additional_information,
          additional_judge_information: ruleForm.value.additional_judge_information,
          about_innovation: ruleForm.value.about_innovation
        }
      })
      if (res.data.code === 10000){
        MessageTips.success('Saved Successfully!')
        // localStorage.setItem('successfulStep', 3)
        emit('getFillStatus')
        getOtherInformation()
      }
    }

    const submitForm = (formEl) => {
      if (!formEl) return;
      formEl.validate((valid) => {
        if (valid) {
          // console.log("submit!");
          saveOtherInformation()
        } else {
          // console.log("error submit!");
          return false;
        }
      });
    };

    watch(ruleForm.value, (newValue) => { //直接监听
      if(newValue.about_innovation)
        ruleFormRef.value.validateField(['about_innovation'])
      if(newValue.additional_judge_information && newValue.additional_judge_information.length >= 5){
        isUpDisable.value = true
      } else {
        isUpDisable.value = false
      }
    })
    onMounted(() => {
      applyId.value && getOtherInformation()
    })
    return {
      t,
      ruleForm,
      ruleFormRef,
      submitForm,
      handleExceed,
      handleChange,
      handleAvatarSuccess,
      beforeAvatarUpload,
      baseUrl,
      handleClick,
      fillStatus,
      applyId,
      getOtherInformation,
      saveOtherInformation,
      uploadFile,
      qiniuConfig,
      handleRemove,
      isDisable,
      handleDelete,
      en_name,
      isUpDisable
    };
  },
});
</script>
<style lang="scss" scoped>
.other-info {
  padding: 50px 210px 0 190px;
  .tips {
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #e34c3e;
    margin-top: 50px;
  }
  .maintitle {
    border-left: 8px solid #e34c3e;
    padding-left: 10px;
    font-size: 21px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #333333;
    margin: 50px 0 20px 0;
    display: flex;
    align-items: center;
  }
  :deep(.el-form-item__label) {
    min-width: 155px;
    font-size: 15px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #262626;
  }
  .quest {
    font-size: 16px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #333333;
    line-height: 22px;
    margin-bottom: 20px;
  }
  .text {
    width: fit-content;
    :deep(.el-form-item__content) {
      width: 100%;
    }
  }
  :deep(.el-textarea),
  :deep(.el-textarea__inner) {
    width: 840px;
    height: 106px;
    border-radius: 4px;
    font-size: 16px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #666666;
  }
  .upload-box {
    :deep(.el-form-item__content) {
      width: 100%;
      display: flex;
      // flex-direction: column;
      .upload-item {
        width: 256px;
        // height: 125px;
        background: #fafafa;
        border-radius: 8px;
        border: 1px solid #e6e6e6;
        margin-left: 50px;
        .el-upload {
          width: 100%;
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          padding: 34px 0 19px;
          > div {
            font-size: 14px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #262626;
            line-height: 20px;
            padding: 0 17px;
            margin-top: 19px;
            > span {
              color: #e34c3e;
            }
          }
        }
      }
      .default_label {
        min-width: 155px;
        overflow: hidden;
        > div {
          min-width: 155px;
        }
        .require-icon {
          color: #e34c3e;
        }
        .tips_msg {
          font-size: 14px;
          font-family: PingFangSC-Medium, PingFang SC;
          color: #262626;
          line-height: 20px;
          > a {
            color: #1890ff;
            text-decoration: none;
          }
        }
      }
    }
    .little-tips1{
      // width: 256px;
      // overflow: hidden;
      // text-overflow: ellipsis;
      // display: -webkit-box;
      // -webkit-line-clamp: 1;
      // -webkit-box-orient: vertical;
       width: 256;
       display: flex;
        .href-title{
          width: 150px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
        }
        .operate {
          color: #1890ff;
          span {
            a{ color: #1890ff;text-decoration: none;}
            margin-left: 6px;
            cursor: pointer;
          }
        }
    }
    .little-tips{
      margin-left: 50px;
      >div{
        width: 256px;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
        .name{
          flex: 1;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
        }
        .del{
          cursor: pointer;
           color: #1890ff;
            a{ color: #1890ff;text-decoration: none;}
        }
      }
  }
  .upload-box,
  .supplement {
    :deep(.el-form-item__label) {
      width: 270px;
    }
  }
  .supplement {
    :deep(.el-textarea),
    :deep(.el-textarea__inner) {
      width: 720px;
      height: 106px;
      border-radius: 4px;
      font-size: 16px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #666666;
    }
  }
  .btns {
    width: 100%;
    margin-top: 50px;
    :deep(.el-form-item__content) {
      display: flex;
      align-items: center;
      justify-content: center;
      .el-button + .el-button {
        margin-left: 60px;
      }
      .el-button {
        width: 150px;
        height: 50px;
        border-radius: 4px;
        font-size: 18px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
      }
    }
  }
}
</style>
