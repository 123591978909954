<template>
  <el-steps :active="successfulStep == 3 ? 4 : Number(successfulStep) + 1" align-center finish-status="success">
    <el-step :title="item.title"  v-for="item in list" :key='item.step'  @click="handleClick(item.step)" :class="item.step === Number(active) && 'activestep'"/>
  </el-steps>

  <!-- <el-button style="margin-top: 12px" @click="next">Next step</el-button> -->
</template>

<script>
  import { defineComponent, ref } from 'vue'
  export default defineComponent({
    props: {
      step: String
    },
    setup(props, {emit}) {
        console.log('steo',props.step)
      localStorage.setItem('step', props.step)
      const active = ref(localStorage.getItem('step') || props.step)
      const successfulStep = ref(localStorage.getItem('successfulStep') || 0)
      const list = ref([
        {title: 'Application Guide', step: 0},
        {title: 'Personal Info', step: 1},
        {title: 'Reference Info', step: 2},
        {title: 'Additional Info', step: 3},
        {title: 'Submit', step: 4},
      ])
      const next = () => {
        if (active.value++ > 4) active.value = 0
      }

      const handleClick = step => {
        if(step == 4){
          successfulStep.value == 3 && emit('setStep', 4)
        }else if(step == 0){
          emit('setStep', 0)
        }else if(Number(successfulStep.value) && (Number(successfulStep.value) + 1 >= step)){
          emit('setStep', step)
        }
      }
      return { active, next, handleClick, successfulStep, list }
    }
  })
</script>
<style lang="scss" scoped>
  .el-steps {
    padding: 0 200px;
  }
  :deep(.el-step__title.is-wait),:deep(.el-step__title.is-process) {
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #666666;
  }
  :deep(.el-step__title.is-finish), :deep(.el-step__title.is-success) {
    color: #e34c3e;
  }
  //   :deep(.el-step.is-center .el-step__head) {
  //     .el-step__icon.is-text {
  //       border-color: #e34c3e;
  //       .el-step__icon-inner {
  //         color: #e34c3e;
  //       }
  //     }
  //   }
  :deep(.el-step__head.is-finish), :deep(.el-step__head.is-success) {
    border-color: #e34c3e;
    color: #e34c3e;
  }
  :deep(.el-step__head.is-process){
     color: var(--el-text-color-placeholder);
    border-color: var(--el-text-color-placeholder);
  }
  .activestep{
    :deep(.el-step__icon-inner){
      color: #fff;
    }
    :deep(.el-step__icon.is-text){
      background: #e34c3e;
      border-color: #e34c3e;
      
        
    }
    :deep(.el-step__head.is-process){
       .el-step__icon.is-text{
            background: #fff;
            border-color: #000;
          .el-step__icon-inner{color: #666666;}
          
      }
    }
    :deep(.el-step__title.is-process){
      font-size: 16px;
      font-weight: 700;
    }
    
  }
</style>
