<template>
  <div class="recommend-info">
    <div class="tips">Tips: No less than two references. Please be aware that we will contact your referee(s) via email if you haven't uploaded all the reference letters and all the letters should be submitted by applicant or sent by referee(s) before the deadline.</div>
    <el-form
      label-position="right"
      label-width="270px"
      :model="ruleForm"
      :inline="true"
      ref="ruleFormRef"
      :rules="rules"
    >
      <div v-for="(domain, index) in ruleForm.domains" :key="index">
        <div class="maintitle">Information of the reference<span class="del" @click.prevent="removeDomain(domain)">Delete</span></div>
        <el-form-item label="Chinese name of the reference:" :prop="'domains.' + index + '.cn_name'" :rules="[{required: true, message: 'This field cannot be left blank.'}]">
          <el-input v-model="domain.cn_name" placeholder="例如，张三"/>
        </el-form-item>
        <el-form-item label="English name of the reference:" :prop="'domains.' + index + '.en_name'" :rules="[{required: true, message: 'This field cannot be left blank.'}]">
          <el-input v-model="domain.en_name" placeholder="for example, John Smith" />
        </el-form-item>
        <el-form-item label="University/Institution/Company:" class="work"  :prop="'domains.' + index + '.company'" :rules="[{required: true, message: 'This field cannot be left blank.'}]">
          <el-input v-model="domain.company" />
        </el-form-item>
        <el-form-item label="Position:"   :prop="'domains.' + index + '.position'" :rules="[{required: true, message: 'This field cannot be left blank.'}]">
          <el-input v-model="domain.position" />
        </el-form-item>
        <el-form-item label="Relationship with you:" :prop="'domains.' + index + '.relation'" :rules="[{required: true, message: 'This field cannot be left blank.'}]">
          <el-input v-model="domain.relation" />
        </el-form-item>
        <el-form-item label="Email:" :prop="'domains.' + index + '.email'" :rules="[{required: true, message: 'This field cannot be left blank.'}]">
          <el-input v-model="domain.email" />
        </el-form-item>
        <el-form-item label="Tel:"  :prop="'domains.' + index + '.phone'" >
          <el-input v-model="domain.phone"  maxlength="20"/>
        </el-form-item>
        <!-- <el-form-item
          label="推荐信提交方式:"
          required
          prop="sex"
          class="upload-box"
        >
          <el-radio-group
            v-model="ruleForm.sex"
            fill="#5EA6F8"
            text-color="#5EA6F8"
          >
            <el-radio label="cn">
              <span>保密推荐信</span>
              <span>
                如果您的推荐人要求推荐内容保密，请选择保密推荐信，您的推荐信会通过推荐人提交至官方。
              </span>
            </el-radio>
            <div>
              <el-radio label="en">
                <span>上传推荐信</span>
                <span>
                  如您的推荐人是国外专家，推荐信可由您自己翻译成中文之后再提交中英文版本，反之亦然。
                </span>
              </el-radio>
            </div>
          </el-radio-group>
          <div class="upload-con">
            <el-upload
              class="upload-item"
              :show-file-list="false"
              :action="`${baseUrl}admin/upload/image`"
              name="image"
              :on-success="handleAvatarSuccess"
              :before-upload="beforeAvatarUpload"
            >
              <img src="@/assets/images/upload.png" alt="" />
              <div>上传中文推荐信，<span>PDF格式</span></div>
            </el-upload>
            <el-upload
              class="upload-item"
              :show-file-list="false"
              :action="`${baseUrl}admin/upload/image`"
              name="image"
              :on-success="handleAvatarSuccess"
              :before-upload="beforeAvatarUpload"
            >
              <img src="@/assets/images/upload.png" alt="" />
              <div>上传英文推荐信，<span>PDF格式</span></div>
            </el-upload>
          </div>
        </el-form-item> -->
      </div>
      <div class="addbtn" @click="addDomain" v-if="ruleForm && ruleForm.domains.length < 5">+ Add reference information (no more than 5 references in total)</div>
      <el-form-item label="" class="btns">
        <el-button plain @click="handleClick(1)">Previous</el-button>
        <el-button type="primary" @click="submitForm(ruleFormRef)">Save</el-button>
        <el-button plain @click="handleClick(3)" :disabled="isDisable">Next</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
import { defineComponent, reactive, ref, onMounted, watch } from "vue";
import { Ajax, MessageTips } from "@/utils";
export default defineComponent({
  components: {},
   props: {
    fillStatus: String,
    applyId: Number
  },
  setup(props, {emit}) {
    const fillStatus = ref(props.fillStatus);
    const applyId = ref(props.applyId)
    const ruleFormRef = ref();
    const ruleForm = ref({
      domains: [
        {
          id: null, 
          cn_name: '',
          en_name: '',
          company: '',
          position: '',
          relation: '',
          email: '',
          phone: '',
        },
        {
          id: null, 
          cn_name: '',
          en_name: '',
          company: '',
          position: '',
          relation: '',
          email: '',
          phone: '',
        },
      ]
    });
    const domainsList = ref([])
    const isDisable = ref(true)
    const isDelete = ref(false)
    const checkCnName = (rule, value, callback) => {
      console.log('reg.test(value)', value)
      if (!value) {
        return callback(new Error('Chinese name is required'))
      }
    };
    
    const checkEmail = (rule, value, callback) => {
      const reg = /^[a-zA-Z0-9_-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/;
      if (reg.test(value)) {
        callback();
      } else if (!value.length) {
        const text = 'E-mail is required'
        callback(new Error(text));
      } else {
        const text = "E-mail error!"
        callback(new Error(text));
      }
    };

    const rules = ref({
      cn_name: [
        {
          required: true,
          message: 'Chinese name is required',
          trigger: "blur",
          validator: checkCnName,
        },
      ],
      email: [
        {
          trigger: "blur",
          required: true,
          validator: checkEmail,
        },
      ],
    });
    const upload = ref();
    const baseUrl = ref("");
    const handleExceed = (files) => {
      console.log("files", files);
      upload.value.clearFiles();
      upload.value.handleStart(files[0]);
    };
    // const submitUpload = () => {
    //   upload.value.submit()
    // }
    const handleChange = (file, fileList) => {
      console.log("file, fileList", file, fileList);
    };
    const handleAvatarSuccess = () => {};
    const beforeAvatarUpload = () => {};
    const handleClick = step => {
      emit('setStep', step)
    }

    const submitForm = (formEl) => {
      console.log("ruleFormRef", ruleFormRef.value, formEl);
      if (!formEl) return;
      formEl.validate((valid) => {
        if (valid) {
          console.log("submit!");
          saveRecommendInformation()
        } else {
          console.log("error submit!");
          return false;
        }
      });
    };

    const addDomain = () => {
      isDelete.value = false
      ruleForm.value.domains.length < 5 && ruleForm.value.domains.push({
        id: null,
        cn_name: '',
        en_name: '',
        company: '',
        position: '',
        relation: '',
        email: '',
        phone: ''
      })
      // domainsList.value = ruleForm.value.domains
    }

    const removeDomain = (item) => {
      const index = ruleForm.value.domains.indexOf(item)
      if (index !== -1 && ruleForm.value.domains.length > 2) {
        // domainsList.value =  JSON.parse(JSON.stringify(ruleForm.value.domains))
        ruleForm.value.domains.splice(index, 1)
        // domainsList.value.forEach(ite => {
        //   if(item.id){
        //     if(ite.id === item.id){
        //        ite.is_del = true
        //     }
        //   } else {
        //     domainsList.value.splice(index, 1)
        //   }
        // }) 
        let Arr =  domainsList.value
        if(item.id){
          Arr.push({...item, is_del: true})
        }
        // console.log('Arr', Arr)
        domainsList.value = Arr
        // domainsList.value.push({...item, is_del: true})
        // console.log('domainsList.value', domainsList.value, ruleForm.value.domains)
        isDelete.value = true
      }
    }

    //获取报名信息详情-推荐信息
    const getRecommendInformation = async() => {
      const res = await Ajax.POST({
        url: '/api/getRecommendInformation',
        data: {
          apply_id: applyId.value
        }
      })
      if (res.data.code === 10000){
        ruleForm.value.domains = res.data.data.length >= 2 ? res.data.data :  [
        {
          id: null, 
          cn_name: '',
          en_name: '',
          company: '',
          position: '',
          relation: '',
          email: '',
          phone: '',
        },
        {
          id: null, 
          cn_name: '',
          en_name: '',
          company: '',
          position: '',
          relation: '',
          email: '',
          phone: '',
        },
      ]
        // domainsList.value = ruleForm.value.domains
        if(res.data.data.length >= 2){
          isDisable.value = false
        }
      }
    }

    // 保存信息
    const saveRecommendInformation = async() => {
      let Arr = []
      const listArr = [...domainsList.value, ...ruleForm.value.domains]
      // console.log('listArr', listArr)
      listArr.forEach(item => {
        Arr.push({
          id: item.id, 
          cn_name: item.cn_name,
          en_name: item.en_name,
          company: item.company,
          position: item.position,
          relation: item.relation,
          email: item.email,
          phone: item.phone,
          is_del: item.is_del
        })
      })
      console.log('Arr', Arr)
      var s = new Set()
      Arr.forEach(i => s.add(i.email))
      if(Arr.length !== s.size) {
        MessageTips.error('You can not enter the same reference more than once. Please name a different one.')
      } else {
        const res = await Ajax.POST({
          url: '/api/saveRecommendInformation',
          data: {
            apply_id: applyId.value,
            reference_list: Arr
          }
        })
        if (res.data.code === 10000){
          emit('getFillStatus')
          MessageTips.success('Saved Successfully!')
          isDisable.value = false
          // localStorage.setItem('successfulStep', 2)
          getRecommendInformation()
        }
      }
      
    }

    watch(ruleForm, (newValue, oldValue) => { //直接监听
      // console.log("ruleForm改变了");
    })

    watch(ruleForm.value, (newValue, oldValue) => { //直接监听
      // console.log("ruleForm.value改变了", newValue.domains, oldValue);
      if(!isDelete.value){
        // domainsList.value = newValue.domains
      }
      isDelete.value = false
    })
    onMounted(() => {
      if(fillStatus.value !== 3){
        applyId.value && getRecommendInformation()
      }
    })
    return {
      ruleForm,
      ruleFormRef,
      rules,
      submitForm,
      handleExceed,
      handleChange,
      handleAvatarSuccess,
      beforeAvatarUpload,
      baseUrl,
      handleClick,
      addDomain,
      removeDomain,
      fillStatus,
      applyId,
      getRecommendInformation,
      saveRecommendInformation,
      domainsList,
      isDisable,
      isDelete
    };
  },
});
</script>
<style lang="scss" scoped>
.recommend-info {
  padding: 0 160px;
  .tips {
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #e34c3e;
    margin-top: 50px;
    line-height: 18px;
  }
  .maintitle {
    border-left: 8px solid #e34c3e;
    padding-left: 10px;
    font-size: 21px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #333333;
    margin: 50px 0 20px 0;
    display: flex;
    align-items: center;
    .del {
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #e34c3e;
      margin-left: 40px;
      cursor: pointer;
    }
  }
  .maintitle:nth-of-type(1) {
    margin-top: 20px;
  }
  :deep(.el-form-item__label) {
    font-size: 15px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #262626;
  }
  :deep(.el-radio__input.is-checked .el-radio__inner) {
    border-color: #5ea6f8;
    background-color: #5ea6f8;
  }
  :deep(.el-radio__inner:hover) {
    border-color: #5ea6f8;
  }
  :deep(.el-radio__input.is-checked + .el-radio__label) {
    color: #333333;
  }
  :deep(.el-form-item__content) {
    width: 260px;
    .el-input__inner {
      width: 260px;
      height: 34px;
      border-radius: 4px;
    }
  }
  .upload-box {
    :deep(.el-form-item__content) {
      width: 100%;
      display: flex;
      flex-direction: column;
      .upload-item {
        width: 256px;
        // height: 125px;
        background: #fafafa;
        border-radius: 8px;
        border: 1px solid #e6e6e6;
        .el-upload {
          width: 100%;
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          padding: 34px 0 19px;
          > div {
            font-size: 14px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #262626;
            line-height: 20px;
            padding: 0 17px;
            margin-top: 19px;
            > span {
              color: #e34c3e;
            }
          }
        }
      }

      .upload-item:nth-of-type(odd) {
        margin-right: 120px;
      }
    }
    .el-radio {
      align-items: flex-start;
      :deep(.el-radio__label) {
        display: flex;
        flex-direction: column;
        line-height: normal;
        > span:nth-of-type(1) {
          font-size: 15px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #333333;
        }
        > span:nth-of-type(2) {
          font-size: 12px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #666666;
          margin-top: 10px;
        }
      }
    }
    .el-radio:nth-of-type(1) {
      margin-bottom: 20px;
    }
    .upload-con {
      display: flex;
    }
  }
  .work {
    width: 100%;
    :deep(.el-form-item__content) {
      width: 800px;
      .el-input__inner {
        width: 800px;
        height: 34px;
        border-radius: 4px;
      }
    }
  }
  .addbtn {
    // width: 358px;
    // height: 50px;
    padding: 15px 70px;
    width: fit-content;
    background: #ffffff;
    border: 1px dashed #e34c3e;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #e34c3e;
    text-align: center;
    line-height: 48px;
    cursor: pointer;
    margin: 30px auto 20px;
  }
  .btns {
    width: 100%;
    margin-top: 50px;
    :deep(.el-form-item__content) {
      display: flex;
      align-items: center;
      justify-content: center;
      .el-button + .el-button {
        margin-left: 60px;
      }
      .el-button {
        width: 150px;
        height: 50px;
        border-radius: 4px;
        font-size: 18px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
      }
    }
  }
}
</style>
