<template>
  <div v-if="!isLoading">
    <div v-if="isApply" class="apply">
      <Steps :step="step" :key="step"  @setStep="setStep"/>
      <!-- 报名须知 -->
      <div class="notice" v-if="step === 0">
        <div class="title">{{ applyDetail.en_title }}</div>
        <div class="content" v-html="applyDetail.en_content"></div>
        <div class="next-box">
          <el-button plain class="next" @click="handleRead" >I have read the above and agree to proceed.</el-button>
          <a :href="applyDetail.attachments" target="_blank">Download Guide</a>
        </div>
      </div>

      <!-- 个人信息 -->
      <PersonalInfo v-if="step === 1 && fillStatus" @setStep="setStep" :fillStatus="fillStatus" :applyId="applyId" @setApplyId="setApplyId" :isShowBeforeInfo="isShowBeforeInfo" @getFillStatus="getFillStatus"/>
      <!-- 推荐人信息 -->
      <RecommendInfo v-if="step === 2 && fillStatus" @setStep="setStep" :fillStatus="fillStatus" :applyId="applyId" @getFillStatus="getFillStatus"/>
      <!-- 其他信息 -->
      <OtherInfo v-if="step === 3 && fillStatus" @setStep="setStep" :fillStatus="fillStatus" :applyId="applyId" @getFillStatus="getFillStatus"/>
      <!-- 提交申请 -->
      <div class="submit-box" v-if="step === 4">
        <!-- <div class="text">
          Once submitted, you can not modify the application materials in the system. We will contact your references via the email addresses you provided to submit the reference letter directly if you passed the primary selection. To confirm submission, please click "Submit", otherwise click "Previous".
        </div> -->
        <div class="text">
          Please be aware that we will contact your referee(s) via email if you haven't uploaded all the reference letters and all the letters should be submitted by applicant or sent by referee(s) before the deadline. <br>
          Once submitted, <span style="color:#e34c3e;font-weight: bold;">you can not modify the application materials in the system. </span> To confirm the submission, please click "Submit", otherwise click "Previous".
        </div>
        <div class="btns">
          <el-button plain class="pre" @click="setStep(3)">Previous</el-button>
          <el-button plain class="submit" @click="handleSubmit">Submit</el-button>
        </div>
      </div>
    </div>

    <!-- 已经报名成功 -->
    <div v-else class="apply-success">
      <img src="@/assets/images/submit.jpg" alt=""/>
      <div class="text">
       Your application has been submitted.
      </div>
      <a href="/" class="back">Home</a>
    </div>
    <isSubmitDialog :year="year" @setStep="setStep"  v-if="isShowDialog" :isShowDialog="isShowDialog" @closeDialog="closeDialog" :isSubmit="isSubmit" @submitSure="submitSure"/>
  </div>
</template>
<script>
import {
  defineComponent,
  ref,
  watch,
  computed,
  onMounted,
  reactive,
  toRefs,
  getCurrentInstance,
  onBeforeUnmount
} from "vue";
import Steps from "./components/steps/index.vue";
import isSubmitDialog from "./components/isSubmitDialog/index.vue";
import PersonalInfo from "./components/personalInfo/index.vue";
import RecommendInfo from "./components/recommendInfo/index.vue";
import OtherInfo from "./components/otherInfo/index.vue";
import { useStore } from "vuex";
import { Ajax, MessageTips } from "@/utils";
import { useRouter } from "vue-router";
export default defineComponent({
  components: { Steps, isSubmitDialog, PersonalInfo, RecommendInfo, OtherInfo },
  setup() {
    // console.log('getCurrentInstance()',getCurrentInstance().appContext.config.globalProperties)
    let store = useStore();
    store.commit("app/SET_ADLISTFOOTER",[])
    const router = useRouter();
    let isChinese = ref(store.getters.language === "en" ? false : true);
    const lang = computed(() => store.getters.language);
    watch(lang, (newLang) => {
      isChinese.value = newLang === "en" ? false : true;
    });
    const isApply = ref(true);
    // Number(localStorage.getItem('step')) || 0
    const step = ref(Number(localStorage.getItem('step')) || 0);
    let applyNotice = reactive({
      applyDetail: {},
    });
    const isLoading = ref(true);
    const fillStatus = ref();
    const applyId = ref()
    const year = ref(getCurrentInstance() ? getCurrentInstance().appContext.config.globalProperties.$year : 2024)
    const isShowDialog = ref(false)
    const isShowBeforeInfo = ref(false)
    const isSubmit = ref(false)
    const applyEntrance=()=>{//报名&提名入口
        Ajax.POST({
          url: "/api/website/applyEntrance",
        }).then((res) => {
          if (res.data.code === 10000) {
            if(res.data.data&&res.data.data.apply_state!==1){
              router.push({path:'/index' })
            }
          }
        });
      }
    applyEntrance();
    //报名步骤
    const setStep = (page) => {
      step.value = Number(page);
      localStorage.setItem('step', page)
    };
    // 获取报名须知
    const getApplyNotice = async () => {
      const res = await Ajax.POST({
        url: "/api/applyNotice",
      });
      // console.log("报名须知res", res);
      if (res.data.code === 10000) {
        applyNotice.applyDetail = Object.assign({}, res.data.data);
      }
      isLoading.value = false;
    };

    // 报名信息填写状态
    const getFillStatus = async (isRefresh) => {
      const res = await Ajax.POST({
        url: "/api/fillStatus",
        data: {
          year: year.value
        }
      });
      // console.log("报名信息填写状态res", res.data);
      if (res.data.code === 10000) {
        fillStatus.value = res.data.data.fill_status;
        applyId.value = res.data.data.apply_id
        localStorage.setItem('fillStatus', res.data.data.fill_status)
        localStorage.setItem('applyId', res.data.data.apply_id)
        localStorage.setItem('successfulStep', res.data.data.step)
        if(isRefresh){
          step.value = Number(res.data.data.step === 3 ? 4 : res.data.data.step);
        }
        if (res.data.data.fill_status === 1){
          isApply.value = false
        } else {
          isApply.value = true
        }
      }
    };

    const handleRead = () => {
      if (fillStatus.value === 3){
        isShowDialog.value = true
      } else {
        step.value = 1
      }
    }

    const closeDialog = (isShowBefore) => {
      isShowDialog.value = false
      isShowBeforeInfo.value = isShowBefore
    }

    const setApplyId = id => {
      applyId.value = id
    }

    const handleSubmit = () => {
      isShowDialog.value = true
      isSubmit.value = true
    }

    const submitSure = async() => {
      const res = await Ajax.POST({
        url: "/api/submit",
        data: {
          apply_id: applyId.value
        }
      });
      if(res.data.code === 10000){
        // MessageTips.success('Submit Successfully!')
        MessageTips.success('Submitted successfully!')
        isShowDialog.value = false
        getFillStatus()
      }
    }
    onMounted(() => {
      if(window.name == ""){
          //  console.log("首次被加载");
       // 在首次进入页面时我们给window.name设置一个固定值(isRefresh) 
           window.name = "isRefresh"; 
        getFillStatus(true)
       }else if(window.name == "isRefresh"){
          //  console.log("页面被刷新");
           getFillStatus()
       }
      getApplyNotice()
    });

    onBeforeUnmount(() => {
      window.name = ""
    })
    return {
      ...toRefs(applyNotice),
      isApply,
      step,
      setStep,
      isChinese,
      getApplyNotice,
      isLoading,
      fillStatus,
      applyId,
      year,
      getFillStatus,
      isShowDialog,
      handleRead,
      closeDialog,
      isShowBeforeInfo,
      setApplyId,
      handleSubmit,
      submitSure,
      isSubmit
    };
  },
});
</script>
<style lang="scss" scoped>
.apply {
  padding: 60px 0 40px;
  width: 1400px;
  margin: 20px auto 70px;
  background: #fff;
  .notice {
    padding: 0 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    .title {
      font-size: 30px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #000000;
      margin: 60px 0 50px;
      line-height: 42px;
    }
    .next-box{
      .next {
        height: 50px;
        background: #e34c3e;
        border-radius: 4px;
        border: none;
        font-size: 18px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #ffffff;
        margin: 50px auto 100px;
      }
      >a{
        color: #e34c3e;
        margin-left: 20px;
      }
    }
    
    .content{
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #000000;
      line-height: 24px;
    }
  }
  .submit-box {
    padding: 0 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    .text {
      font-size: 16px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #333333;
      line-height: 26px;
      margin: 50px 0;
      // padding: 0 100px;
    }
    .pre {
      width: 150px;
      height: 50px;
      border-radius: 4px;
      border: 1px solid #bbbbbb;
      font-size: 18px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #000000;
      margin-right: 60px;
    }
    .submit {
      width: 150px;
      height: 50px;
      background: #e34c3e;
      border-radius: 4px;
      border: none;
      font-size: 18px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #ffffff;
    }
  }
}
.apply-success {
  padding: 100px 0 110px;
  display: flex;
  flex-direction: column;
  align-items: center;
  > img {
    width: 50px;
  }
  .text {
    font-size: 16px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #333333;
    text-align: center;
    margin-top: 30px;
  }
  .back {
    display: block;
    width: 150px;
    height: 50px;
    background: #e34c3e;
    border-radius: 4px;
    border: none;
    font-size: 18px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #ffffff;
    margin-top: 100px;
    text-decoration: none;
    line-height: 50px;
    text-align: center;
  }
}
</style>
